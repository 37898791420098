
/* The container */
.checkbox-custom {
  display: block;
  position: relative;
  padding-left: 35px;
  font-weight: 400;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-custom input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: var(--secondary);
    border: 1px solid var(--secondary-border);
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-custom input:checked~.checkmark-checkbox {
  background-color: var(--primary);
}

/* Create the checkmark-checkbox/indicator (hidden when not checked) */
.checkmark-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark-checkbox when checked */
.checkbox-custom input:checked~.checkmark-checkbox:after {
  display: block;
}

/* Style the checkmark-checkbox/indicator */
.checkbox-custom .checkmark-checkbox:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid var(--secondary);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}





/* The container */
.checkbox-table {
  position: relative;
  padding-left: 35px;
  font-weight: 400;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-table input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-table {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: #F5F5FA;
  border: 1px solid #DDDDE3;
  border-radius: 4px;
}


/* When the checkbox is checked, add a blue background */
.checkbox-table input:checked~.checkmark-table {
  background-color: var(--primary);
}

/* Create the checkmark-table/indicator (hidden when not checked) */
.checkmark-table:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark-table when checked */
.checkbox-table input:checked~.checkmark-table:after {
  display: block;
}

/* Style the checkmark-table/indicator */
.checkbox-table .checkmark-table:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid var(--white);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}