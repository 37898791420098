.table {
  width: 100%;
  margin-bottom: 1rem;
}
.table> :not(:first-child) {
  border-top: none;
}

.table-head-custom{
  color: var(--secondary-dark);
  thead{
    tr{
      th{
        width: auto;
        font-weight: 500;
        border: none;
      }
    }
  }
  tbody{
    tr{
      td{
        height: 60px;
         vertical-align: middle;
      }
    }
  }
}

.img-table-item{
 width: 40px;
 height: 40px;
  border-radius: 6px;
  object-fit: cover;
}