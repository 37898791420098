//FONT
.font-weight-bolder {
  font-weight: 700;
}

.font-weight-bold {
  font-weight: 500;
}

.font-size-lg {
  font-size: 20px;
}

.font-size-md {
  font-size: 1rem;
}

$font-size-count: 20;

%float-styles {
  float: left;
}

@for $i from 1 through $font-size-count {
  .font-#{$i} { font-size: #{$i}px; }
}

//TEXT
.text-primary {
  color: var(--primary) !important;
}
.text-dark-primary {
  color: var(--primary-dard) !important;
}

.text-secondary {
  color: var(--secondary-dark) !important;
}

.text-dark {
  color: var(--secondary-dark-bolder);
}


.text-warning {
  color: var(--warning) !important;
}