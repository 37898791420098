@import "button";
@import "card";
@import "text";

.shadow {
  box-shadow: var(--shadow) !important;
}

.cursor-pointer{
  cursor: pointer;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}


.modal-custom {
  .modal-dialog {
    min-width: 70vw;
    width: 100%;

    .modal-content {
      border-radius: 20px;

      .modal-header {
        padding: 30px;

        .modal-title {
          font-weight: 700;
          font-size: 20px;
        }
      }

      .modal-body {
        padding: 30px;
      }

      .modal-footer {
        padding: 30px;
      }
    }
  }
}

.card-title{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: var(--secondary-dark-bolder);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F5F5FA;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DDDDE3;
  border-radius: 10px;
}

// first column
.freeze-column-12 tbody > tr > td:nth-child(1),
.freeze-column-12 > thead > tr:nth-child(1) > th:nth-child(1) {
  background-color: var(--bs-gray-100);
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
}

// second column
.freeze-column-12 > tbody > tr > td:nth-child(2),
.freeze-column-12 > thead > tr:nth-child(1) > th:nth-child(2) {
  background-color: var(--bs-gray-100);
  position: sticky;
  position: -webkit-sticky;
  left: 30px;
  z-index: 1;
}

.freeze-column-12 > tbody > tr > td:nth-child(3) {
  left: 250px;
  z-index: 1;
}

// last column
.freeze-column-12 > tbody > tr > td:nth-last-child(1),
.freeze-column-12 > thead > tr:nth-last-child(1) > th:nth-last-child(1) {
  background-color: var(--bs-gray-100);
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  z-index: 1;
}