.flex-root{
  position: relative;
}
.flex-wrapper{
  width: 100%;
  min-height: 100vh;
  padding-top: 80px;
}

.flex-content-aside{
  padding-left: 300px;
}

.flex-content {
  padding-left: 100px;
}

.container-fluid{
  padding: 50px !important; 
}