.nav-tabs{
  // border: 0;
  .nav-link{
    margin: 0;
    padding: 25px 30px;
    border: 0; 
    border-top-left-radius: 0; 
    border-top-right-radius: 0;
    &:hover{
      border: 0;
    }
  }
  .nav-link.active{
    border: 0;
    background: linear-gradient(10.44deg, #3DBD66 -21.11%, #91DB88 181.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    border-bottom: 3px solid #91DB88;
  }
}
