.aside{
  
  height: 100%;
  position: fixed;
  top: 80px;
  left: 0;
  padding: 30px 0;
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  z-index: 1000;
  overflow: auto;
  padding-bottom: 80px;
  .aside-menu{
    a {
      text-decoration: none;
      color: $secondary-dark;
      padding: 15px 22px;
    };
    .aside-menu-item{
      font-weight: 500;
      font-size: 16px;

      img{
        width: 21px;
        height: 21px;
      }
    }
    .menu-item-active {
      border-right: 2px solid var(--primary);
      .submenu-item-active, .aside-menu-parent, .active {
        color: var(--primary) !important;
      }
    }
    .submenu-item-active {
      a {
        color: var(--primary) !important;
      }
    }
    .aside-menu-parent {
      cursor: pointer;
      text-decoration: none;
      color: $secondary-dark;
      padding: 15px 22px;
    }
    .aside-menu-item {
      list-style-type: none;
    }

    // Style for second-level submenu items
    .aside-submenu-item {
      list-style-type: none;
      padding-left: 20px; // Adjust left padding for indentation if needed
    }
  }
}