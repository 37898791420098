// BUTTON
.btn-primary {
  background: linear-gradient(10.44deg, var(--primary) -21.11%, var(--primary-light) 181.65%) !important;
  border-radius: 14px;
  color: var(--white) !important;
  padding: 9px 20px;
  border: 0;
  outline: none;

  &:hover {
    background: linear-gradient(10.44deg, var(--primary) -21.11%, var(--primary-light) 181.65%) !important;
    color: var(--white);
  }

  &:active {
    background: linear-gradient(10.44deg, var(--primary) -21.11%, var(--primary-light) 181.65%) !important;
    color: var(--white);
  }
}

.btn-secondary {
  height: 40px;
  left: 910px;
  bottom: 20px;
  background: var(--secondary-light);
  border-radius: 14px;
  border: 0;
  outline: none;
  color: var(--secondary-dark) !important;

  &:hover {
    background: linear-gradient(10.44deg, var(--secondary-dark-hover) -21.11%, var(--secondary-light) 181.65%) !important;
    color: var(--secondary-dark);
  }

  &:active {
    background: linear-gradient(10.44deg, var(--secondary-dark-hover) -21.11%, var(--secondary-light) 181.65%) !important;
    color: var(--secondary-dark);
  }
}


.btn-warning {
  height: 40px;
  left: 910px;
  bottom: 20px;
  background: var(--warning);
  border-radius: 14px;
  border: 0;
  outline: none;
  color: var(--white) !important;

  &:hover {
    background: linear-gradient(10.44deg, var(--secondary-dark-hover) -21.11%, var(--secondary-light) 181.65%) !important;
    color: var(--secondary-dark);
  }

  &:active {
    background: linear-gradient(10.44deg, var(--secondary-dark-hover) -21.11%, var(--secondary-light) 181.65%) !important;
    color: var(--secondary-dark);
  }
}