$primary: #4FAB6C;
$primary-hover: #3DBD66;
$primary-light: #91db88;

$warning: #FFF5C7;
$warning-light: #FFF5C7;

$info: #0d6efd;

$secondary: #F5F5FA;
$secondary-border: #DDDDE3; 

$secondary-light: #EBEBF0;
$secondary-dark: #808089;
$secondary-dark-bolder: #515158;
$secondary-dark-hover: #cbcbd1;

$white: #fff;

$shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
$shadow-lg: 0px 4px 20px rgba(0, 0, 0, 0.08);



:root{
  --primary: #4FAB6C;
  --primary-hover: #3DBD66;
  --primary-light: #91db88;
  --primary-dard: #00AB56;

  --warning: #FFC400;
  --warning-light: #fae6a6;


  --secondary: #F5F5FA;
  --secondary-border: #DDDDE3 ;
  --secondary-light: #EBEBF0;
  --secondary-dark:#808089;
  --secondary-dark-bolder:#515158;
  --secondary-dark-hover: #cbcbd1;




  --white: #fff;

  --shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  --shadow-lg: 0px 4px 20px rgba(0, 0, 0, 0.08);

  --radius-circle: 14px;

  --spacing-margin: 30px;
}