//BACKGROUND
.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-primary-light {
  background-color: var(--primary-light) !important;
}

.bg-waring-light {
  background-color: var(--warning-light) !important;
}