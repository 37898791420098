
.input-field{
  padding: 8px;
  border: 1px solid var(--secondary-border);
  outline: none;
  box-shadow: none;
  height: 40px !important;
}
.input-search{
  border-radius: 14px;
  padding-left: 48px;
  min-width: 300px;
}

label{
  font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: var(--secondary-dark);
}
