.card {
  border-radius: 20px !important;
  padding: 30px !important;
  box-shadow: var(--shadow-lg);
  z-index: 100;
  margin-bottom: 40px;
  // min-width: 80vw;

  // overflow-x: scroll;
}

.card-header {
  border-radius: 20px !important;
}

.card-tool-filter {
  flex: 1;
}